import React, { useState } from 'react';
import {
  IonAlert,
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonInput,
  IonItem,
  IonModal,
  IonText,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import routes from '../../constants/routes.json';
import { useTranslate } from '~/i18n/translate';
import { WorkingState } from '~/types';
import { Button, FlexColumn, FlexRow } from '../defaultUIComponents';
import IgnoredLogoutTimerWarningApi from '~/api/LogoutWarningApi';
import {
  useCheckEndPause,
  useCheckEndWork,
  useEndWork,
  usePauseWork,
  useStartWork,
  useWorkingState,
} from '~/api/TimetrackingApi';
import { useCompanySettigns } from '~/api/CompanySettingsApi';
import { useLogout } from '~/api/AuthApi';
import { useEmployeeId } from '~/state/auth';
import { useLocation, useNavigate } from 'react-router';
import IgnoredPauseTimerWarningApi from '~/api/PauseWarningApi';

function secondsToMinutesAndSeconds(seconds: number): [number, number] {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return [minutes, remainingSeconds];
}

function createEndPauseMessage(minutes: number, seconds: number): string {
  return `Versuchen Sie es in ${minutes} Minuten und ${seconds} Sekunden nochmal`;
}

function createEndWorkMessage(minutes: number, seconds: number): string {
  return `Sie benötigen noch ${minutes} Minuten und ${seconds} Sekunden Pausenzeit bevor ein Logout möglich ist`;
}

interface TimeTrackingButtonsProps {}

export const TimeTrackingButtons: React.FC<TimeTrackingButtonsProps> = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [flow, setFlow] = useState<null | 'pause' | 'logout'>(null);
  const { data: workingState = WorkingState.NOTHING } = useWorkingState();
  const { data: companySettings } = useCompanySettigns();
  const t = useTranslate();
  const location = useLocation();
  const navigate = useNavigate();

  const { mutate: startWork, isPending: startWorkPending } = useStartWork();
  const { mutate: checkEndWork } = useCheckEndWork();
  const { mutate: checkEndPause } = useCheckEndPause();
  const employeeId = useEmployeeId();

  const { mutate: pauseWork, isPending: pauseWorkPending } = usePauseWork({
    onSuccess: async () => {
      if (location.pathname.startsWith('/run')) {
        await navigate(routes.RUNNING_JOBS);
      }
    },
  });
  const { mutate: handleEndWork, isPending: endWorkPending } = useEndWork();
  const { mutate: logout } = useLogout();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [inputReason, setInputReason] = useState('');
  const [inputError, setInputError] = useState('');
  const isLoading = startWorkPending || pauseWorkPending || endWorkPending;

  const endWork = () => {
    if (companySettings?.logout_on_leave) {
      return logout();
    }
    handleEndWork();
  };

  function startWorkIfBreakIsValid(workingState: WorkingState) {
    if (workingState !== WorkingState.PAUSE) {
      startWork();
      setShowAlert(false);
    } else {
      checkEndPause(employeeId, {
        onSuccess: (remainingSeconds) => {
          if (remainingSeconds > 0) {
            const [minutes, seconds] =
              secondsToMinutesAndSeconds(remainingSeconds);
            const message = createEndPauseMessage(minutes, seconds);
            setAlertMessage(message);
            setFlow('pause');
            setShowAlert(true);
          } else {
            setFlow(null);
            startWork();
          }
        },
      });
    }
  }

  function endWorkIfTotalBreakIsValid() {
    checkEndWork(employeeId, {
      onSuccess: (remainingSeconds) => {
        if (remainingSeconds > 0) {
          const [minutes, seconds] =
            secondsToMinutesAndSeconds(remainingSeconds);
          const message = createEndWorkMessage(minutes, seconds);
          setAlertMessage(message);
          setFlow('logout');
          setShowAlert(true);
        } else {
          setFlow(null);
          endWork();
        }
      },
    });
  }

  return (
    <>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => {
          setFlow(null);
          setShowAlert(false);
        }}
        header={t('logout.alert.header')}
        message={alertMessage}
        buttons={[
          {
            text:
              flow === 'logout'
                ? t('logout.alert.action.logout')
                : t('logout.alert.action.endpause'),
            handler: () => {
              setShowConfirmationModal(true);
              return false;
            },
          },
          'OK',
        ]}
      />
      <IonModal isOpen={showConfirmationModal}>
        <IonHeader>
          <IonToolbar>
            <IonTitle>{t('logout.alert.action.confirmation.header')}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonInput
              value={inputReason}
              placeholder={t('logout.alert.action.confirmation.text')}
              onIonChange={(e) => {
                setInputReason(e.detail.value!);
                setInputError('');
              }}
            />
          </IonItem>
          {inputError && <IonText color="danger">{inputError}</IonText>}
        </IonContent>
        <IonFooter>
          <IonToolbar>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setShowConfirmationModal(false);
                  setInputReason('');
                  setInputError('');
                }}
              >
                {t('logout.alert.action.confirmation.cancel')}
              </IonButton>
              <IonButton
                onClick={async () => {
                  if (inputReason.trim().length < 3) {
                    setInputError(
                      t('logout.alert.action.confirmation.error_length'),
                    );
                    return;
                  }

                  setShowConfirmationModal(false);

                  switch (flow) {
                    case 'pause':
                      await IgnoredPauseTimerWarningApi.queries.createIgnoredPauseTimerWarning(
                        inputReason,
                      );
                      startWork();
                      break;
                    case 'logout':
                      await IgnoredLogoutTimerWarningApi.queries.createIgnoredLogoutTimerWarning(
                        inputReason,
                      );
                      endWork();
                      break;
                  }

                  setFlow(null);
                  setShowAlert(false);
                  setInputReason('');
                }}
              >
                {t('logout.alert.action.confirmation.confirm')}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonFooter>
      </IonModal>
      {workingState === WorkingState.WORKING ? (
        <FlexRow>
          <FlexColumn>
            <Button
              onClick={() => pauseWork()}
              disabled={isLoading}
              className="ml-1 mr-1 h-10"
            >
              {t('timeTracking.pause')}
            </Button>
          </FlexColumn>
          <FlexColumn>
            <Button
              onClick={() => endWorkIfTotalBreakIsValid()}
              disabled={isLoading}
              className="mr-1  h-10"
            >
              {t('timeTracking.end')}
            </Button>
          </FlexColumn>
        </FlexRow>
      ) : (
        <Button
          onClick={() => startWorkIfBreakIsValid(workingState)}
          disabled={isLoading}
          className="ml-1 mr-2  h-10"
        >
          {t(
            workingState === WorkingState.NOTHING
              ? 'timeTracking.start'
              : 'timeTracking.pauseEnd',
          )}
        </Button>
      )}
    </>
  );
};
