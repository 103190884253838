import { create } from 'zustand';
import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import { EmployeeRole } from '~/types';

export type AuthStore = {
  flow: 'login' | 'logout';
  status: 'pending' | 'logged_in' | 'logged_out' | 'error';
  roles?: EmployeeRole[];
  token?: string;
  needs_two_factor?: boolean;
  authenticated: boolean;
  feature_flags?: string;
  id?: number;
  employee_id?: number;
  company_name?: string;
  company_id?: number
  authenticate(payload: {
    token: string;
    needs_two_factor: boolean;
    id: number;
    employee_id: number;
    feature_flags: string;
    company_name: string;
    company_id: number;
  }): Promise<void>;
  loadAuth(): Promise<void>;
  logout(): Promise<void>;
  startLogoutFlow(): void;
  startLoginFlow(): void
  confirmTwoFactor(): Promise<void>;
};

const key = 'v1_its4plan_app_auth';

export const useAuthStore = create<AuthStore>((set, get) => ({
  authenticated: false,
  flow: 'login',
  status: 'logged_out',
  async authenticate(payload): Promise<void> {
    if (payload.needs_two_factor) {
      const state = {
        authenticated: false,
        flow: 'login' as const,
        ...payload,
      };
      set(state);
      await Preferences.set({
        key,
        value: JSON.stringify(state),
      });
    } else {
      const state = { authenticated: true, ...payload };
      set(state);
      axios.defaults.headers.common['Authorization'] = `Bearer ${state.token}`;
      await Preferences.set({
        key,
        value: JSON.stringify(state),
      });
    }
  },
  async loadAuth() {
    const auth = await Preferences.get({ key });
    if (auth.value) {
      const state = JSON.parse(auth.value) as AuthStore;
      if (!state.authenticated) {
        await this.logout();
      } else {
        axios.defaults.headers.common[
          'Authorization'
          ] = `Bearer ${state.token}`;
        set(state);
      }
    }
  },
  async logout(): Promise<void> {
    delete axios.defaults.headers.common['Authorization'];
    await Preferences.remove({ key });
    set(useAuthStore.getInitialState(), true);
  },
  startLogoutFlow() {
    set({ flow: 'logout' });
  },
  startLoginFlow() {
    set({ flow: 'login' });
  },
  async confirmTwoFactor(): Promise<void> {
    set({ authenticated: true });
    axios.defaults.headers.common['Authorization'] = `Bearer ${get().token}`;
    await Preferences.set({
      key,
      value: JSON.stringify(get()),
    });
  },
}));

export function useEmployeeId() {
  return useAuthStore((state) => state.employee_id)!;
}
