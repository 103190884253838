import axios from 'axios';

const queries = {
  async createIgnoredPauseTimerWarning(reason: string): Promise<void> {
    await axios.post(`/warnings/ignored_pause_timer/create/`, null, {
      params: { reason },
    });
  },
};

const IgnoredPauseTimerWarningApi = {
  queries,
};

export default IgnoredPauseTimerWarningApi;
