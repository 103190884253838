import '@total-typescript/ts-reset';
import { IonApp } from '@ionic/react';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Theme variables */
import './theme/variables.css';
import './theme/tailwind.css';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useSettingsStore } from './state/settings';
import { RouterProvider } from 'react-router';
import { useRouter } from './router';

const App: React.FC = () => {
  const { fetchLanguage } = useSettingsStore();

  useEffect(() => {
    void fetchLanguage();
  }, []);

  // GMT-0
  moment.tz.setDefault('Africa/Monrovia');
  const router = useRouter();

  return (
    <IonApp>
      <RouterProvider router={router} />
    </IonApp>
  );
};

export default App;
