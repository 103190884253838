/* tslint:disable */
/* eslint-disable */
/**
 * Authentication Service Api
 * Authentication Service Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerifyCodeRequest
 */
export interface VerifyCodeRequest {
    /**
     * 
     * @type {number}
     * @memberof VerifyCodeRequest
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof VerifyCodeRequest
     */
    code: string;
}

/**
 * Check if a given object implements the VerifyCodeRequest interface.
 */
export function instanceOfVerifyCodeRequest(value: object): value is VerifyCodeRequest {
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('code' in value) || value['code'] === undefined) return false;
    return true;
}

export function VerifyCodeRequestFromJSON(json: any): VerifyCodeRequest {
    return VerifyCodeRequestFromJSONTyped(json, false);
}

export function VerifyCodeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyCodeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'code': json['code'],
    };
}

  export function VerifyCodeRequestToJSON(json: any): VerifyCodeRequest {
      return VerifyCodeRequestToJSONTyped(json, false);
  }

  export function VerifyCodeRequestToJSONTyped(value?: VerifyCodeRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'companyId': value['companyId'],
        'code': value['code'],
    };
}

