import { unwrap, useApiContext } from './common';
import { useMutation } from '@tanstack/react-query';
import axios, { isAxiosError } from 'axios';
import { useAuthStore } from '~/state/auth';
import { useCompanySettigns } from './CompanySettingsApi';
import { useEndWork } from './TimetrackingApi';
import routes from '../constants/routes.json';
import { useIonToast } from '@ionic/react';
import { useTranslate } from '~/i18n/translate';
import { AuthResponse } from '~/types';
import { useNavigate } from 'react-router';

type LoginRequest = {
  username: string;
  password: string;
  company_id: number;
};

export function useLogin() {
  const t = useTranslate();
  const { authenticate, startLoginFlow } = useAuthStore();
  const navigate = useNavigate();
  const [present] = useIonToast();
  return useMutation({
    mutationFn: (req: LoginRequest) =>
      axios.post<AuthResponse>('/auth/login/', req).then(unwrap),
    onSuccess: async (data) => {
      startLoginFlow();
      await authenticate({
        ...data,
        id: Number(data.id),
        employee_id: Number(data.employee_id),
      });
      if (data.needs_two_factor) {
        await navigate(routes.TWO_FACTOR_AUTH, { replace: true });
      } else {
        await navigate(routes.TIME_TRACKING, { replace: true });
      }
    },
    onError(error) {
      if (isAxiosError(error) && error.response?.status === 401) {
        void present({
          message: t('authenticate.wrongInput'),
          duration: 1000,
          color: 'danger',
        });
      } else {
        void present({
          message: t('error.unknown') + ' ' + error.message,
          duration: 1000,
          color: 'danger',
        });
      }
    },
  });
}

export function useLogout() {
  const { data: companySettings } = useCompanySettigns();
  const { mutateAsync: endWork } = useEndWork();
  const { logout, needs_two_factor, startLogoutFlow } = useAuthStore();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async () => {
      if (needs_two_factor) {
        startLogoutFlow();
        return { codeScanning: true };
      }
      if (companySettings?.logout_on_leave) {
        await endWork();
      }
      await logout();
      return { codeScanning: false };
    },
    onSuccess: ({ codeScanning }) => {
      if (codeScanning) return navigate(routes.TWO_FACTOR_AUTH);
      else return navigate(routes.AUTHENTICATE);
    },
  });
}

export function useVerifyToken(
  onSuccess?: () => void,
) {
  const t = useTranslate();
  const { data: companySettings } = useCompanySettigns();
  const { mutateAsync: endWork } = useEndWork();
  const { confirmTwoFactor, logout, company_id, flow } = useAuthStore();
  const { loginApi } = useApiContext();
  const navigate = useNavigate();
  const [present] = useIonToast();
  return useMutation({
    mutationFn: (code: string) => {
      if (!company_id) throw new Error('No company id found');
      return loginApi.verifyOtp({
        verifyCodeRequest: { code, companyId: company_id },
      });
    },
    onSuccess: async () => {
      onSuccess?.();
      if (flow === 'login') {
        await confirmTwoFactor();
        return navigate(routes.TIME_TRACKING);
      }
      if (flow === 'logout') {

        if (companySettings?.logout_on_leave) {
          await endWork();
        }
        await logout();
      }
      return navigate(routes.AUTHENTICATE);
    },
    onError: async () => {
      return present({
        message: t('authenticate.wrongQRCode'),
        duration: 1000,
        color: 'danger',
      });
    },
  });
}
