import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonModal,
  IonTextarea,
  IonTitle,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { closeSharp } from 'ionicons/icons';
import React, { useRef } from 'react';
import { useTranslate } from '~/i18n/translate';
import {
  AggregatedOrderedProject,
  useOrderedProjectDone,
} from '~/api/OrderingApi';
import { OrderedProject } from '@its4plan/ordering';

interface OrderDoneModalProps {
  isOpen: boolean;
  close: () => void;
  orderProject: AggregatedOrderedProject | OrderedProject | undefined;
}

export const OrderDoneModal: React.FC<OrderDoneModalProps> = ({
  isOpen,
  close,
  orderProject,
}) => {
  const [show] = useIonToast();
  const translate = useTranslate();
  const amountRef = useRef<HTMLIonInputElement>(null);
  const commentRef = useRef<HTMLIonTextareaElement>(null);
  const submitRef = useRef<HTMLIonButtonElement>(null);

  const { mutate: orderDone } = useOrderedProjectDone({
    onSuccess: () => {
      void show({
        message: translate('ordertile.done'),
        duration: 5000,
        color: 'success',
      });
      close();
    },
    onError: () => {
      void show({
        message: translate('ordertile.error'),
        duration: 5000,
        color: 'danger',
      });
    },
  });

  const onModalSubmit = () => {
    if (!amountRef.current?.value) {
      console.error('Amount is required');
      void show({
        message: translate('ordertile.amountRequired'),
        duration: 5000,
        color: 'danger',
      });
      return;
    }
    if (orderProject) {
      orderDone({
        id: Number(orderProject.id),
        orderedProjectDoneRequest: {
          amount: Number(amountRef.current?.value),
          comment: commentRef.current?.value ?? '',
        },
      });
    }
  };

  return (
    <IonModal isOpen={isOpen}>
      <IonHeader>
        <IonToolbar className="pr-2">
          <IonTitle>{translate('orderTile.headerModal')}</IonTitle>
          <IonIcon
            icon={closeSharp}
            slot="end"
            size="xl"
            onClick={() => close()}
            className="text-2xl"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="px-2 py-1">
          <IonInput
            type="number"
            inputMode="numeric"
            label={translate('orderTile.amount.label')}
            labelPlacement="floating"
            min="1"
            max={`${orderProject?.amount ?? 1}`}
            required
            ref={amountRef}
            value={1}
            onIonInput={() => {
              const value = amountRef.current?.value;
              submitRef.current!.disabled = !(value && Number(value) > 0);
            }}
          />
          <IonTextarea
            value=""
            rows={3}
            label={translate('orderTile.comment')}
            labelPlacement="floating"
            ref={commentRef}
          />
        </div>
      </IonContent>
      <IonFooter className="fixed bottom-0 left-0 right-0">
        <IonToolbar>
          <IonButton
            disabled={(Number(amountRef.current?.value) ?? 0) > 0}
            expand="full"
            onClick={onModalSubmit}
            ref={submitRef}
          >
            {translate('orderTile.closeModal')}
          </IonButton>
        </IonToolbar>
      </IonFooter>
    </IonModal>
  );
};
