/* tslint:disable */
/* eslint-disable */
/**
 * Authentication Service Api
 * Authentication Service Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {number}
     * @memberof LoginRequest
     */
    companyId: number;
}

/**
 * Check if a given object implements the LoginRequest interface.
 */
export function instanceOfLoginRequest(value: object): value is LoginRequest {
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    return true;
}

export function LoginRequestFromJSON(json: any): LoginRequest {
    return LoginRequestFromJSONTyped(json, false);
}

export function LoginRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
    };
}

  export function LoginRequestToJSON(json: any): LoginRequest {
      return LoginRequestToJSONTyped(json, false);
  }

  export function LoginRequestToJSONTyped(value?: LoginRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'companyId': value['companyId'],
    };
}

