import axios from 'axios';

const queries = {
  async createIgnoredLogoutTimerWarning(reason: string): Promise<void> {
    await axios.post(`/warnings/ignored_logout_timer/create/`, null, {
      params: { reason },
    });
  },
};

const IgnoredLogoutTimerWarningApi = {
  queries,
};

export default IgnoredLogoutTimerWarningApi;
