/* tslint:disable */
/* eslint-disable */
/**
 * Authentication Service Api
 * Authentication Service Api
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginRequest,
  VerifyCodeRequest,
  WebTokenResponse,
} from '../models/index';
import {
    LoginRequestFromJSON,
    LoginRequestToJSON,
    VerifyCodeRequestFromJSON,
    VerifyCodeRequestToJSON,
    WebTokenResponseFromJSON,
    WebTokenResponseToJSON,
} from '../models/index';

export interface LoginOperationRequest {
    loginRequest: LoginRequest;
}

export interface VerifyLoginCodeRequest {
    verifyCodeRequest: VerifyCodeRequest;
}

export interface VerifyOtpRequest {
    verifyCodeRequest: VerifyCodeRequest;
}

/**
 * 
 */
export class LoginApi extends runtime.BaseAPI {

    /**
     */
    async loginRaw(requestParameters: LoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['loginRequest'] == null) {
            throw new runtime.RequiredError(
                'loginRequest',
                'Required parameter "loginRequest" was null or undefined when calling login().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters['loginRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async login(requestParameters: LoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.loginRaw(requestParameters, initOverrides);
    }

    /**
     */
    async verifyLoginCodeRaw(requestParameters: VerifyLoginCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WebTokenResponse>> {
        if (requestParameters['verifyCodeRequest'] == null) {
            throw new runtime.RequiredError(
                'verifyCodeRequest',
                'Required parameter "verifyCodeRequest" was null or undefined when calling verifyLoginCode().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/login/verify/code`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyCodeRequestToJSON(requestParameters['verifyCodeRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WebTokenResponseFromJSON(jsonValue));
    }

    /**
     */
    async verifyLoginCode(requestParameters: VerifyLoginCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WebTokenResponse> {
        const response = await this.verifyLoginCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async verifyOtpRaw(requestParameters: VerifyOtpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['verifyCodeRequest'] == null) {
            throw new runtime.RequiredError(
                'verifyCodeRequest',
                'Required parameter "verifyCodeRequest" was null or undefined when calling verifyOtp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/login/verify/otp`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyCodeRequestToJSON(requestParameters['verifyCodeRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async verifyOtp(requestParameters: VerifyOtpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.verifyOtpRaw(requestParameters, initOverrides);
    }

}
