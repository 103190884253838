import { IonContent, IonLoading, IonPage } from '@ionic/react';
import * as dateFns from 'date-fns';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FlexColumn, FlexRow, Text } from '~/components/defaultUIComponents';
import { DateInput } from '~/components/defaultUIComponents/Input/DateInput';
import Toolbar from '../../components/Toolbar/Toolbar';
import { useTranslate } from '~/i18n/translate';
import { StampType, Timestamp, TimestampDate, WorkingState } from '~/types';
import { millisecondsToMomentNotation, secondsToHMin } from '~/utils/timeUtils';
import { useWorkTypeToString } from '~/utils/workTypeUtil';
import { Day } from './Day';
import {
  useCurrentTimstStamp,
  useDates,
  useTimetrackingInfo,
  useWorkingState,
} from '~/api/TimetrackingApi';

export const TimeTracking: React.FC = () => {
  const translate = useTranslate();
  const currentWorkDurationRef = useRef<HTMLIonTextElement>();
  const currentPauseDurationRef = useRef<HTMLIonTextElement>();

  const [start, setStart] = useState<Date>(
    dateFns.startOfWeek(new Date(), { weekStartsOn: 1 }),
  );
  const [end, setEnd] = useState<Date>(
    dateFns.endOfWeek(new Date(), { weekStartsOn: 1 }),
  );

  const req = { start, end };

  const { data: dates = [], isLoading } = useDates(req);
  const { data: workingState = WorkingState.NOTHING } = useWorkingState(req);
  const { data: currentTimeStamp } = useCurrentTimstStamp(req);
  const { data: monthInfo } = useTimetrackingInfo(req);

  const workTypeToString = useWorkTypeToString();
  useTimersV2(
    dates,
    currentTimeStamp,
    workingState,
    (duration: string) => {
      currentWorkDurationRef.current!.innerText = duration;
    },
    (duration: string) => {
      currentPauseDurationRef.current!.innerText = duration;
    },
  );

  const [openCollapseId, setOpenCollapseId] = useState<number | undefined>();

  const filteredDates = useMemo(() => {
    if (!!start && !!end) {
      return dates.filter(
        (d) =>
          dateFns.isSameDay(d.date, start) ||
          dateFns.isAfter(d.date, start) ||
          dateFns.isSameDay(d.date, end) ||
          dateFns.isBefore(d.date, end),
      );
    }
    return dates;
  }, [start, end, dates]);

  const filteredSummedTimeInSeconds = useMemo(() => {
    let summedTimeInSeconds = 0;

    filteredDates.forEach((d) => {
      summedTimeInSeconds += d.working_time;
    });
    return summedTimeInSeconds;
  }, [filteredDates]);

  return (
    <IonPage>
      <Toolbar>{translate('timeTracking.toolbar')}</Toolbar>
      <IonContent>
        <IonLoading isOpen={isLoading} />
        <FlexColumn className="px-3 pt-4 pb-3">
          <FlexRow justifyContent="between">
            <Text>{workTypeToString(StampType.WORK) + ':'}</Text>
            {currentTimeStamp && <Text ref={currentWorkDurationRef} />}
          </FlexRow>
          <FlexRow justifyContent="between">
            <Text>{workTypeToString(StampType.PAUSE) + ':'}</Text>
            {currentTimeStamp && <Text ref={currentPauseDurationRef} />}
          </FlexRow>
          <div className="w-full flex gap-1">
            <div className="w-full">
              <Text>timetracking.from</Text>
              <DateInput
                onChange={setStart}
                value={start}
                className="rounded border border-black text-black"
              />
            </div>
            <div className="w-full">
              <Text>timetracking.to</Text>
              <DateInput
                value={end}
                onChange={setEnd}
                className="rounded border border-black text-black"
              />
            </div>
          </div>
          <FlexRow justifyContent="between">
            <Text>{`${translate(
              'timeTracking.expected_time',
            )} ${getExpectedTimeSuffix(translate, start, end)}`}</Text>
            <Text>{secondsToHMin(monthInfo?.expected_time ?? 0, true)}</Text>
          </FlexRow>
          <FlexRow justifyContent="between">
            <Text>timeTracking.chosenSummedTime.prefix</Text>
            <Text>{secondsToHMin(filteredSummedTimeInSeconds, true)}</Text>
          </FlexRow>
        </FlexColumn>

        {_.orderBy(filteredDates, (date) => date.date.valueOf(), 'desc')?.map(
          (date, index) => (
            <Day
              key={index}
              date={date}
              openCollapseId={openCollapseId}
              setOpenCollapseId={setOpenCollapseId}
            />
          ),
        )}
      </IonContent>
    </IonPage>
  );
};

function TimerPause({
  timeStamp,
  currentDurationPause,
}: {
  timeStamp: Timestamp | null | undefined;
  currentDurationPause: string;
}) {
  if (timeStamp) {
    return <Text>{currentDurationPause}</Text>;
  }
  return null;
}

function getExpectedTimeSuffix(
  translate: (key: any) => any,
  start: Date | null,
  end: Date | null,
) {
  if (!start || !end) {
    return translate('timeTracking.thisWeek');
  }
  return translate('timeTracking.chosenTime');
}

function summedTimeOfToday(timestamps: Timestamp[]) {
  let workDuration = 0;
  timestamps.forEach((t) => {
    if (t.end && t.start < t?.end)
      workDuration += dateFns.differenceInMilliseconds(t.end, t.start);
  });
  return workDuration;
}

export function useTimersV2(
  dates: TimestampDate[],
  currentTimeStamp: Timestamp | null | undefined,
  workingState: WorkingState,
  onCurrentDurationWorkUpdate: (duration: string) => void,
  onCurrentDurationPauseUpdate: (duration: string) => void,
) {
  const startOfToday = useMemo(
    () => new Date(new Date().setHours(0, 0, 0, 0)),
    [],
  );
  const endOfToday = useMemo(
    () => new Date(new Date().setHours(23, 59, 59, 9999)),
    [],
  );
  const timestampsOfToday = useMemo(() => {
    const todayAsDate = dates.filter(
      (d) =>
        d.date.valueOf() >= startOfToday.valueOf() &&
        d.date.valueOf() < endOfToday.valueOf(),
    )[0];
    return todayAsDate?.timestamps || [];
  }, [dates, startOfToday, endOfToday]);

  const workTimestampsOfToday = timestampsOfToday.filter(
    (t) => t.type === StampType.WORK,
  );
  const pauseTimestampsOfToday = timestampsOfToday.filter(
    (t) => t.type === StampType.PAUSE,
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeframe = {
        start: currentTimeStamp?.start
          ? new Date(currentTimeStamp.start)
          : new Date(),
        end: new Date(),
      };

      if (workingState === WorkingState.WORKING) {
        onCurrentDurationWorkUpdate(
          millisecondsToMomentNotation(
            dateFns.differenceInMilliseconds(timeframe.end, timeframe.start) +
              summedTimeOfToday(workTimestampsOfToday),
          ),
        );
        onCurrentDurationPauseUpdate(
          millisecondsToMomentNotation(
            summedTimeOfToday(pauseTimestampsOfToday),
          ),
        );
      } else if (workingState === WorkingState.PAUSE) {
        onCurrentDurationPauseUpdate(
          millisecondsToMomentNotation(
            dateFns.differenceInMilliseconds(timeframe.end, timeframe.start) +
              summedTimeOfToday(pauseTimestampsOfToday),
          ),
        );
        onCurrentDurationWorkUpdate(
          millisecondsToMomentNotation(
            summedTimeOfToday(workTimestampsOfToday),
          ),
        );
      }
    });
    return () => clearInterval(intervalId);
  }, [currentTimeStamp, workTimestampsOfToday, pauseTimestampsOfToday]);
}

export function useTimers(
  dates: TimestampDate[],
  currentTimeStamp: Timestamp | null | undefined,
  workingState: WorkingState,
) {
  const [currentDurationWork, setCurrentDurationWork] = useState('');
  const [currentDurationPause, setCurrentDurationPause] = useState('');
  const startOfToday = new Date(new Date().setHours(0, 0, 0, 0));
  const endOfToday = new Date(new Date().setHours(23, 59, 59, 9999));

  const timestampsOfToday = useMemo(() => {
    const todayAsDate = dates.filter(
      (d) =>
        d.date.valueOf() >= startOfToday.valueOf() &&
        d.date.valueOf() < endOfToday.valueOf(),
    )[0];
    return todayAsDate?.timestamps || [];
  }, [dates, startOfToday, endOfToday]);
  const summedTimeOfToday = (timestamps: Timestamp[]) => {
    let workDuration = 0;
    timestamps.forEach((t) => {
      if (t.end && t.start < t?.end)
        workDuration += dateFns.differenceInMilliseconds(t.end, t.start);
    });
    return workDuration;
  };
  const workTimestampsOfToday = timestampsOfToday.filter(
    (t) => t.type === StampType.WORK,
  );
  const pauseTimestampsOfToday = timestampsOfToday.filter(
    (t) => t.type === StampType.PAUSE,
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeframe = {
        start: currentTimeStamp?.start
          ? new Date(currentTimeStamp.start)
          : new Date(),
        end: new Date(),
      };

      if (workingState === WorkingState.WORKING) {
        setCurrentDurationWork(
          millisecondsToMomentNotation(
            dateFns.differenceInMilliseconds(timeframe.end, timeframe.start) +
              summedTimeOfToday(workTimestampsOfToday),
          ),
        );
        setCurrentDurationPause(
          millisecondsToMomentNotation(
            summedTimeOfToday(pauseTimestampsOfToday),
          ),
        );
      } else if (workingState === WorkingState.PAUSE) {
        setCurrentDurationPause(
          millisecondsToMomentNotation(
            dateFns.differenceInMilliseconds(timeframe.end, timeframe.start) +
              summedTimeOfToday(pauseTimestampsOfToday),
          ),
        );
        setCurrentDurationWork(
          millisecondsToMomentNotation(
            summedTimeOfToday(workTimestampsOfToday),
          ),
        );
      }
    }, 500);
    return () => {
      clearTimeout(intervalId);
    };
  }, [currentTimeStamp, workingState]);
  return {
    currentDurationWork,
    currentDurationPause,
  };
}
