import {
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonPage,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { useVerifyToken } from '~/api/AuthApi';
import { useTranslate } from '~/i18n/translate';
import { person } from 'ionicons/icons';

export function TwoFactorPage() {
  const t = useTranslate();
  const [pin, setPin] = useState('');
  const { mutate: verify, isPending, isError, error } = useVerifyToken();

  const handleSubmit = () => {
    verify(pin);
  };

  useEffect(() => {
    if (pin.length === 2) {
      handleSubmit();
    }
  }, [pin]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t('twofactor.verify.title')}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="flex flex-col justify-center items-center py-5 px-2 gap-5 h-full">
          <IonIcon
            className="mt-5 mb-8 text-4xl"
            icon={person}
            color="primary"
          />
          <IonInput
            value={pin}
            maxlength={2}
            inputmode="numeric"
            autofocus
            onIonInput={(e) => setPin(e.detail.value || '')}
            errorText={isError ? error?.message : undefined}
            helperText={t('twofactor.verify.pin.helper')}
            style={{
              color: '#000',
              textAlign: 'center',
              fontSize: '5rem',
              width: '10rem',
              marginBottom: '2rem',
            }}
          />
          {isError && <div style={{ marginTop: '1rem', color: 'red' }}>Error: {error?.message}</div>}
        </div>
      </IonContent>

      <IonFooter>
        <IonButton
          expand="block"
          onClick={handleSubmit}
          disabled={pin.length !== 2}
        >
          {isPending ? <IonSpinner /> : t('twofactor.verify.pin')}
        </IonButton>
      </IonFooter>
    </IonPage>
  );
}
